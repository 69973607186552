import React, { useState } from 'react';
import { Form, Col } from 'react-bootstrap';
// Interfaces
import { Auth } from '../../interfaces/auth';
// Utils
import { Button } from '../../utils';

const Login: React.FC<{}> = () => {
	return (
		<Form noValidate>
			<Form.Row>
				<Col>
					<Form.Group controlId="email">
						<Form.Label>Correo electrónico</Form.Label>
						<Form.Control type="email" name="email" />
					</Form.Group>
				</Col>
			</Form.Row>
			<Form.Row>
				<Col>
					<Form.Group controlId="email">
						<Form.Label>Contraseña</Form.Label>
						<Form.Control type="password" name="password" />
					</Form.Group>
				</Col>
			</Form.Row>
			<Button type="submit" text="Entrar">
				Submit form
			</Button>
		</Form>
	);
};

export default Login;
