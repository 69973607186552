import React from "react";
import { Navbar, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
// Utils
import { Button } from "../../utils";

function Navigation(props: any) {
	const { showLogin, showRegister } = props;

	return (
		<React.Fragment>
			<Navbar expand="sm" bg="light">
				<Navbar.Brand>
					<Link to="/">Navbar</Link>
				</Navbar.Brand>
				<Navbar.Toggle aria-controls="responsive-navbar-nav" />
				<Navbar.Collapse id="responsive-navbar-nav">
					<Nav className="ml-auto">
						<Nav.Item>
							<Link className="btn btn-link btn-sm" to="/">
								Inicio
							</Link>
						</Nav.Item>
						<Nav.Item>
							<Button
								text="Registrate"
								onClick={() => showRegister(true)}
								color="link"
								size="sm"
							/>
						</Nav.Item>
						<Nav.Item>
							<Button
								text="Iniciar sesión"
								onClick={() => showLogin(true)}
								color="link"
								size="sm"
							/>
						</Nav.Item>
					</Nav>
				</Navbar.Collapse>
			</Navbar>
		</React.Fragment>
	);
}

export default React.memo(Navigation);
