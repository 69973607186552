import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

// Components
import Layout from './components/layouts/layout';
import Home from './components/home';

const App: React.FC<{}> = () => {
	return (
		<BrowserRouter>
			<Layout>
				<Switch>
					<Route exact path="/" component={Home} />
					<Route path="*">
						<NoMatch />
					</Route>
				</Switch>
			</Layout>
		</BrowserRouter>
	);
};

const NoMatch: React.FC<{}> = () => {
	return (
		<div>
			<h3>
				No match for <code>404</code>
			</h3>
		</div>
	);
};

export default App;
