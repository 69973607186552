import React from "react";
import { Modal } from "react-bootstrap";

function RTSmodal(props: any) {
	const {
		sizeModal,
		showModal,
		showAnimation,
		textTitle,
		onClose,
		children
	} = props;

	return (
		<Modal
			size={sizeModal}
			show={showModal}
			onHide={() => onClose()}
			animation={showAnimation}
			dialogClassName="modal-90w"
			aria-labelledby="modal"
		>
			{textTitle && (
				<Modal.Header closeButton>
					<Modal.Title id="modal">{textTitle}</Modal.Title>
				</Modal.Header>
			)}
			<Modal.Body>{children}</Modal.Body>
		</Modal>
	);
}

export default RTSmodal;
