import React from "react";
import ReactDOM from "react-dom";
import "./assets/app.scss";

// Components
import App from "./App";
import * as serviceWorker from "./serviceWorker";

const container = document.getElementById("root");

ReactDOM.render(<App />, container);

serviceWorker.unregister();
