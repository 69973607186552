import React from "react";

class Home extends React.Component {
	constructor(props: any) {
		super(props);

		this.state = {};
	}

	render() {
		return "hola";
	}
}

export default Home;
