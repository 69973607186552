import React from "react";
import { Button } from "react-bootstrap";

const RTSbutton: React.FC<any> = ({
	color,
	size,
	type,
	disabled,
	onClick,
	text
}) => {
	return (
		<Button
			variant={color}
			size={size}
			onClick={() => (onClick ? onClick() : null)}
			disabled={disabled}
			type={type}
		>
			{text}
		</Button>
	);
};

export default RTSbutton;
