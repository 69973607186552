import React, { useState } from "react";
import { Container } from "react-bootstrap";
// Utils
import { Modal } from "../../utils";
// Components
import Navbar from "./navbar";
import AuthenticateMe from "../auth/login";
import RegisterMe from "../auth/register";

function Layout(props: any) {
	const [showLogin, setLogin] = useState(false);
	const [showRegister, setRegister] = useState(false);

	return (
		<React.Fragment>
			{/**
			 * 	We set the navigation bar
			 *	Receive parameters to define the status of the modal
			 */}
			<Navbar
				showLogin={(show: boolean) => setLogin(show)}
				showRegister={(show: boolean) => setRegister(show)}
			/>
			{/**
			 *  Components for user authentication
			 *  Receive parameters to show or hide modal
			 */}
			{showLogin && (
				<Modal sizeModal="md" showModal onClose={() => setLogin(false)}>
					<AuthenticateMe />
				</Modal>
			)}

			{showRegister && (
				<Modal sizeModal="md" showModal onClose={() => setRegister(false)}>
					<RegisterMe />
				</Modal>
			)}
			{/**
			 * 	We set the complete default page container
			 *	Hook
			 */}
			<Container fluid>{props.children}</Container>
		</React.Fragment>
	);
}

export default Layout;
